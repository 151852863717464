<template>
  <div :key="localKey">
    <!--Начало блока, если есть хотя бы один телефон-->
    <v-card
      v-if="itemPhones.length"
      outlined
      class="grey lighten-5 pt-0"
    >
      <v-card-text>
        <v-row
          v-if="itemPhones.length"
          class="align-center align-self-center"
        >
          <!--Начало телефоны-->
          <v-col
            cols="8"
            class="pa-0"
          >
            <PhoneSelect
              :current-phone.sync="getCurrentPhone"
              :phone-info-masked="phoneInfoMasked"
              :phone-status="phoneStatus"
              :key="String(itemPhones.length)"
            />
          </v-col>
          <v-col
            cols="1"
            class="pa-0"
          >
            <p class="subtitle-1 pt-3">{{ itemPhones.length }}</p>
          </v-col>
          <!--Конец телефонов-->
          <!--Начало кнопок для телефонов-->
          <v-col
            cols="3"
            class="d-flex flex-wrap justify-center"
            style="margin-top: -10px;"
          >
            <slot name="btn"></slot>
            <PhoneList
              :current-phone.sync="getCurrentPhone"
              :phone-info="itemPhones"
              :category-name="categoryName"
              @input="handlePhoneListInput($event)"
            />
          </v-col>
          <!--Конец кнопок для телефонов-->
        </v-row>
        <v-row>
          <!--Начало дополнительных полей (статусов и тд)-->
          <v-col
            cols="12"
            class="pa-0"
          >
            <slot name="custom">
              <PhoneCustom
                v-model="activePhone"
                :is-advert-phone="isAdvertPhone"
              />
            </slot>
          </v-col>
          <!--Конец дополнительных полей (статусов и тд)-->
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <!--Начало истории созвонов-->
      <v-card-text v-if="showHistory">
        <v-row>
          <CallHistory
            v-model="callHistory"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <!--Конец истории созвонов-->
    <!--Конец блока, если есть хотя бы один телефон-->
    <!--Начало блока, если нет ни одного телефона-->
    <v-card
      v-else
      outlined
      class="grey lighten-5 pt-0"
    >
      <v-row>
        <EmptyPhone
          :phone-info="itemPhones"
          :category-name="categoryName"
        />
      </v-row>
    </v-card>
    <!--Конец блока, если нет ни одного телефона-->
  </div>
</template>

<script>
import setDraggable from '@/components/utils/dragable'
import CallHistory from '@/components/views/admin/common/BasePhone/CallHistory'
import PhoneList from '@/components/views/admin/common/BasePhone/PhoneList'
import EmptyPhone from '@/components/views/admin/common/BasePhone/EmptyPhone'
import PhoneSelect from '@/components/views/admin/common/BasePhone/PhoneSelect'
import basePhone from '@/components/mixins/basePhone'
import PhoneCustom from '@/components/views/admin/common/BasePhone/PhoneCustom'

export default {
  components: { PhoneCustom, PhoneSelect, EmptyPhone, PhoneList, CallHistory },
  mixins: [basePhone],
  name: 'BasePhone',
  computed: {
    callHistory: {
      get () {
        return this.itemPhones[this.currentPhoneItemIndex].phone.call_history
      },
      set (value) {
        this.itemPhones[this.currentPhoneItemIndex].phone.call_history = value
      }
    },
    activePhone: {
      get () {
        return this.itemPhones[this.currentPhoneItemIndex]
      },
      set (value) {
        this.itemPhones[this.currentPhoneItemIndex] = value
      }
    },
    showHistory () {
      return this.activePhone.phone.status === 61
    }
  },
  mounted () {
    setDraggable()
    this.processPhoneInfo()
  }
}
</script>

<style scoped>

</style>
